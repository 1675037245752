'use strict';

// generate ranks
function computeRank( wardList, wardNameList ) {
  //generate rank map
  var populationDensity = [];
  var wardArea = [];
  var noOfHouseholds = [];
  var complaintCount = [];
  var parkAreaPerPopulation = [];
  var relativeRank = [];

  for(var index in wardList) {
    var ward = wardList[index];
    wardNameList[ward.properties.WARD_NAME] = ward.properties;

    if(!(ward.properties.publicToiletCount)) {
      ward.properties.publicToiletCount = 0;
    }
    if(!(ward.properties.population_density in populationDensity)) {
      populationDensity.push(ward.properties.population_density);
    }
    if(!(ward.properties.ward_area in wardArea)) {
      wardArea.push(ward.properties.ward_area);
    }
    if(!(ward.properties.no_of_households in noOfHouseholds)) {
      noOfHouseholds.push(ward.properties.no_of_households);
    }
    if(!(ward.properties.complaint_count in complaintCount)) {
      complaintCount.push(ward.properties.complaint_count);
    }
    if(!(ward.properties.park_area_per_population in parkAreaPerPopulation)) {
      parkAreaPerPopulation.push(ward.properties.park_area_per_population);
    }
  }


  complaintCount = complaintCount.sort();
  parkAreaPerPopulation = parkAreaPerPopulation.sort().reverse();
  populationDensity = populationDensity.sort().reverse();
  noOfHouseholds = noOfHouseholds.sort().reverse();
  wardArea = wardArea.sort();

  wardList.map((wardDetails) => {
    var ele = wardDetails.properties;
    ele.complaintCountRank = complaintCount.indexOf(ele.complaint_count);
    ele.parkAreaPerPopulationRank = parkAreaPerPopulation.indexOf(ele.park_area_per_population);
    ele.populationDensityRank = populationDensity.indexOf(ele.population_density);
    ele.noOfHouseholdsRank = noOfHouseholds.indexOf(ele.no_of_households);
    ele.wardAreaRank = wardArea.indexOf(ele.ward_area);

    var rank = (
      ele.wardAreaRank +
      ele.parkAreaPerPopulationRank +
      ele.populationDensityRank +
      ele.noOfHouseholdsRank +
      ele.wardAreaRank );
    ele.relativeRank = rank;
    relativeRank.push(rank);
  });

  relativeRank = relativeRank.sort();
  wardList.map((wardDetails) => {
    ward.properties.relativeRank = relativeRank.indexOf(wardDetails.properties.relativeRank);
  });
}

window.analysis = ( data ) => {
  computeRank( data.features, window.wardNameList );
};
